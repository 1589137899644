/* Define variables to be used throughout entire website */
:root {
  --primary-color: #ffffff;
  --secondary-color: #b3b3b3;
  --accent-color: #AA0303;
  --success-color: #52C76D;
  --error-color: #EB3223;
  --primary-color-rgb: 255, 255, 255;
  --secondary-color-rgb: 179, 179, 179;
  --accent-color-rgb: 170, 3, 3;
  --success-color-rgb: 82, 199, 109;
  --error-color-rgb: 235, 50, 35;
}

/* Use this for sections with white backgrounds */
.white-section {
  background-color: var(--primary-color);
  color: black;
}

/* Use this for sections with grey backgrounds */
.grey-section {
  background-color: var(--secondary-color);
  color: white;
}

body {
  font-family: "Roboto", sans-serif;
  background: var(--secondary-color);  /* Must match color of the last section */
  background-attachment: fixed;
  color: #8e8e8e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
  margin-bottom: 2.5rem;  /* Must match height of copyright footer*/
}

h1, h2, h3 {
  font-family: "Oswald", sans-serif;
}

html {
  scroll-padding-top: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  font-size: 50px;
  margin: 0px;
  margin-bottom: 20px;
}

.secondary-title {
  font-size: 30px;
  margin: 3px;
}

.text {
  font-size: 20px;
}

.text.white-section {
  color: black;
}

.text.grey-section {
  color: white;
}

.left-padding {
  padding-right: 10px;
  padding-left: 140px;
}

.right-padding{
  padding-right: 140px;
  padding-left: 10px;
}

.left-margin {
  margin-right: 10px;
  margin-left: 140px;
}

.right-margin{
  margin-right: 140px;
  margin-left: 10px;
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100vw;
}

a:visited {
  text-decoration: none;
}

.nav-link {
  color: var(--primary-color);
}

.form-input {
    padding: 8px;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    flex: 1;
    max-width: 100%;
}

.form-row {
  display: flex;
  gap: 15px;
  width: 100%;
  margin: 0px;
  flex-wrap: wrap;
}

@media (min-width: 1250px) and (max-width: 1800px) {
  .text {
    font-size: 18px;
  }

  .secondary-title {
    font-size: 28px;
  }
}

@media (min-width: 741px) and (max-width: 1249px) {
  .left-padding {
    padding-left: 70px;
  }

  .right-padding {
    padding-right: 70px;
  }

  .left-margin {
    margin-left: 70px;
  }
  
  .right-margin{
    margin-right: 70px;
  }

  .text {
    font-size: 18px;
  }

  .title {
    font-size: 45px;
  }

  .secondary-title {
    font-size: 25px;
  }

  .section {
    padding-top: 37px;
    padding-bottom: 37px;
  }
}

@media (max-width: 740px) {
  .left-padding {
    padding-right: 10px;
    padding-left: 10px;
  }

  .right-padding {
    padding-right: 10px;
    padding-left: 10px;
  }

  .left-margin {
    margin-left: 10px;
  }
  
  .right-margin{
    margin-right: 10px;
  }

  .text {
    font-size: 15px;
  }

  .title {
    font-size: 30px;
  }

  .secondary-title {
    font-size: 23px;
  }

  .section {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .form-row {
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 561px) {
  .text {
    font-size: 12px;
  }
}